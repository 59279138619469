.card-size{
    width: 200px;
    height: 300px;
    border-radius: 10px; 
}

.card-container{
    position: relative;
}

.card-overlay{
    position: absolute;
    width: 200px;
    height: 100%;
    background: rgba(0, 0, 0, 0.774);
    font-size: 12px;
    color: white;
    overflow: scroll;
    border-radius: 10px;
    padding: 10px;
    opacity: 0;

}

.card-overlay:hover{
    opacity: 1;  
    transition: opacity 0.1s ease-in-out; 
}


.card-title{
    margin-bottom: 5px !important;
    font-size: 12.4px;
    font-weight: bolder;
}



/***** Responsive Styles ****/


/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px){
    .card-size{
        width: 160px;
        height: 240px;
        border-radius: 10px; 
    }

    .card-overlay{
        width: 160px;
    }

}

 /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .card-size{
        width: 120px;
        height: 180px;
        border-radius: 10px; 
    }

    .card-overlay{
        width: 120px;
    }
  
}

 /* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .card-size{
        width: 80px;
        height: 120px;
        border-radius: 10px; 
    }

    .card-overlay{
        width: 80px;
    }
    
}