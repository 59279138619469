@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300&display=swap);
body{
    background-color: rgb(218, 218, 218) !important;
    font-family: 'Nunito', sans-serif !important;

}

.navbar{
    background-color: rgb(27, 27, 27);
    font-family: 'Rajdhani', sans-serif;
}

.nav-item h3{
margin: 0 30px 0 30px;

}

.inactive{
    color: white;
}

.inactive:hover{
    color: rgb(94, 93, 93);
}


.active{
color: rgb(40, 171, 168);
   
}



/* ******** Responsive Styles *********/
@media (max-width: 767px){
   .nav{
        display: block !important;
    }

    .navbar h3{
        font-size: 20px;
    }



}


@media (max-width: 575.98px){
    .navbar h3{
         font-size: 15px;
         font-weight: bolder;
     }
 
 }

#home-container{
    text-align: center;
    width: 600px;
    line-height: 30px;
    margin: 40px auto 20px auto;
}


@media (max-width: 400px) { 
    #home-container{
        width: 320px ;
    }
 }

 @media (min-width: 401px) and (max-width: 575px) { 
    #home-container{
        width: 403px ;
    }
 }

@media (min-width: 576px) and (max-width: 767.98px) { 
    #home-container{
        width: 500px ;
    }
 }


.card-size{
    width: 200px;
    height: 300px;
    border-radius: 10px; 
}

.card-container{
    position: relative;
}

.card-overlay{
    position: absolute;
    width: 200px;
    height: 100%;
    background: rgba(0, 0, 0, 0.774);
    font-size: 12px;
    color: white;
    overflow: scroll;
    border-radius: 10px;
    padding: 10px;
    opacity: 0;

}

.card-overlay:hover{
    opacity: 1;  
    -webkit-transition: opacity 0.1s ease-in-out;  
    transition: opacity 0.1s ease-in-out; 
}


.card-title{
    margin-bottom: 5px !important;
    font-size: 12.4px;
    font-weight: bolder;
}



/***** Responsive Styles ****/


/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px){
    .card-size{
        width: 160px;
        height: 240px;
        border-radius: 10px; 
    }

    .card-overlay{
        width: 160px;
    }

}

 /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .card-size{
        width: 120px;
        height: 180px;
        border-radius: 10px; 
    }

    .card-overlay{
        width: 120px;
    }
  
}

 /* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .card-size{
        width: 80px;
        height: 120px;
        border-radius: 10px; 
    }

    .card-overlay{
        width: 80px;
    }
    
}
#posters-container{
    justify-items: center;
    justify-content: center;
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    grid-gap: 30px;
    gap: 30px;
}


.loader{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-img{
    width: 40px;
    height: 40px;    
}


.no-results{
    width: 100%;
    height: 50vh;
    margin: 50px 0px;
    text-align: center;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    #posters-container{
        grid-template-columns: repeat(auto-fit, 80px);
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px){
    #posters-container{
        grid-template-columns: repeat(auto-fit, 120px);
    }

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    #posters-container{
        grid-template-columns: repeat(auto-fit, 160px);
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    #posters-container{
        grid-template-columns: repeat(auto-fit, 200px);
    }
}


select {
 	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	 -o-appearance: none;
		appearance: none;
}

.select-item{
  background: white;
	border: 1px solid rgb(211, 211, 211);
	color: rgb(114, 114, 114);
	border-radius: 0px;
	width: 100%;
	padding: 10px 30px;
}




select::-ms-expand {
    display: none;
  }


 


