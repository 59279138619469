#posters-container{
    justify-items: center;
    justify-content: center;
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    gap: 30px;
}


.loader{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-img{
    width: 40px;
    height: 40px;    
}


.no-results{
    width: 100%;
    height: 50vh;
    margin: 50px 0px;
    text-align: center;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    #posters-container{
        grid-template-columns: repeat(auto-fit, 80px);
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px){
    #posters-container{
        grid-template-columns: repeat(auto-fit, 120px);
    }

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    #posters-container{
        grid-template-columns: repeat(auto-fit, 160px);
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    #posters-container{
        grid-template-columns: repeat(auto-fit, 200px);
    }
}
