
select {
 	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	 -o-appearance: none;
		appearance: none;
}

.select-item{
  background: white;
	border: 1px solid rgb(211, 211, 211);
	color: rgb(114, 114, 114);
	border-radius: 0px;
	width: 100%;
	padding: 10px 30px;
}




select::-ms-expand {
    display: none;
  }


 

