@import url('https://fonts.googleapis.com/css?family=Rajdhani:300&display=swap');


.navbar{
    background-color: rgb(27, 27, 27);
    font-family: 'Rajdhani', sans-serif;
}

.nav-item h3{
margin: 0 30px 0 30px;

}

.inactive{
    color: white;
}

.inactive:hover{
    color: rgb(94, 93, 93);
}


.active{
color: rgb(40, 171, 168);
   
}



/* ******** Responsive Styles *********/
@media (max-width: 767px){
   .nav{
        display: block !important;
    }

    .navbar h3{
        font-size: 20px;
    }



}


@media (max-width: 575.98px){
    .navbar h3{
         font-size: 15px;
         font-weight: bolder;
     }
 
 }
